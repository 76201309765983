
import { BlobServiceClient } from '@azure/storage-blob';

import React, { useState,useEffect,useCallback} from "react";
import moment from 'moment';


import { Button, Grid ,Typography} from "@mui/material";
import {
    DataGrid,
    GridToolbarContainer,
    GridToolbarExportContainer,
    GridCsvExportMenuItem,
  } from '@mui/x-data-grid';
import { Icon,Input,InputLabel } from '@mui/material';
import { PictureAsPdf } from '@mui/icons-material';
import * as XLSX from 'xlsx';
import { useParams, useHistory } from "react-router-dom";
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';

//Components
import CMLabel from '../../components/atoms/CMLabel';
import CMDatePicker from '../../components/molecules/CMDatePicker';
import CMButton from '../../components/atoms/CMButton';

//Constants
import {MomentLocaleEs} from '../../constants/MomentLocaleEs';
//Services
import * as OrdenesService from '../../services/OrdenesService';
import * as ProveedorService from '../../services/ProveedorService';


import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import CMTextField from "../../components/atoms/CMTextField";
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import CMIconButton from '../../components/atoms/CMIconButton';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
const MySwal = withReactContent(Swal)


const FacturasPendientesAdjuntarMasivoVer = () => {
  const history = useHistory();
    const [rowsdata, setRowsdata] = useState([]);
    const [rowsgrid, setRowsgrid] = useState([]);
    const [comment,setComment] = useState([]);
    const [pdf,setPdf] = useState('');
    const [xml,setXml] = useState('');
    const [cdr,setCdr] = useState('');
    const [guia,setGuia] = useState('');
    const [ncpdf,setNcpdf] = useState('');
    const [ncxml,setNcxml] = useState('');
    const [ndpdf,setNdpdf] = useState('');
    const [ndxml,setNdxml] = useState('');
    
    const [boton,setBoton] = useState(false);
    const [enviar,setEnviar] = useState('');
    const user = JSON.parse(window.localStorage.getItem("user"));
    const useFakeMutation = () => {
      return React.useCallback(
        (user) =>
          new Promise((resolve, reject) =>
            setTimeout(() => {
              if (user.name?.trim() === '') {
                reject(new Error("Error al guardar el usuario: el nombre no puede estar vacÃ­o"));
              } else {
                resolve({ ...user, name: user.name?.toUpperCase() });
              }
            }, 200),
          ),
        [],
      );
    };
    const mutateRow = useFakeMutation();
    const [snackbar, setSnackbar] = useState(null);
    const [dataresponse,setDataresponse] = useState(null);
    const handleCloseSnackbar = () => setSnackbar(null);
    const handleProcessRowUpdateError = React.useCallback((error) => {
      setSnackbar({ children: error.message, severity: 'error' });
    }, []);
    const [sumaTotal, setSumaTotal] = useState(0);
    const [SumaSinIGV, setSumaSinIGV] = useState(0);
    const [SumaIGV, setSumaIGV] = useState(0);

    const { ordenid } = useParams();

    // useEffect(() => {
    //     OrdenesService.getNEIDMasivo(setRowsdata, ordenid, user.token);
    // }, [ordenid]);  
    
    const groupedRows = rowsdata.reduce((acc, item) => {
      // Crear una clave condicional combinada
      const key = item.nombre_proveedor || item.otroCampo; // Agrupa si 'nombre_proveedor' o 'otroCampo' se repiten
      // Si no existe la clave en el acumulador, la creamos
      if (!acc[key]) {
        acc[key] = [];
      }
      // Agregar el elemento al grupo correspondiente
      acc[key].push(item);
      return acc;
    }, {});
    

    useEffect(() => {
        OrdenesService.getNEIDMasivo((data) => {
            // Verifica si los datos son un array o un objeto
            if (Array.isArray(data)) {
                // Si es un array, actualiza directamente
                setRowsdata(data);
            } else {
                // Si es un objeto, asume que es el formato esperado
                setRowsdata(data);
            }
        }, ordenid, user.token);
    }, [ordenid, user.token]);
    

    useEffect(() => {
      if(enviar==='Se registraron correctamente todos los documentos'){
        // ProveedorService.getEnviarPDF(ordenid, user.token); enviar pdf pero se desactivo

      Swal.fire({
        title: 'Se registró correctamente!',
        icon: 'success',
        showCancelButton: false,
        confirmButtonText: 'OK',
      }).then((result) => {
        if (result.isConfirmed) {
            history.push(`/facturaspendienteadjuntarproveedor`);
            history.go(0);
        }
      });
    }
  }, [enviar]);

    const rows2 = rowsdata.map((row, index) => ({
        id: index,        
        oc_documento: row.numero_documento_orden,
        ne_documento: row.numero_documento, 
        observaciones: row.observaciones,     
        id_proveedor: row.id_proveedor,
        total: row.total_pago_vencido,
        sin_igv: row.total_antes_descuento,
        igv: row.impuesto,
    }));
    
    useEffect(() => {
      // Calcular la suma de todas las filas en el campo 'total' de rows2
      const suma = rows2.reduce((acc, row) => acc + row.total, 0);
      setSumaTotal(suma);
    }, [rows2]);

    useEffect(() => {
      // Calcular la suma de todas las filas en el campo 'sin_igv' de rows2
      const suma = rows2.reduce((acc, row) => acc + row.sin_igv, 0);
      setSumaSinIGV(suma);
    }, [rows2]);

    useEffect(() => {
      // Calcular la suma de todas las filas en el campo 'igv' de rows2
      const suma = rows2.reduce((acc, row) => acc + row.igv, 0);
      setSumaIGV(suma);
    }, [rows2]);
    

    //Agregando filtro de documenteo para servicios,compras y proveedores
    const ids_proveedores_exception = [738,750,784,795,797,798,799,803,806,868,875,888,909,912,919,949,960,1177,1178,1179,1180,1181,
    1182,1183,1184,1185,1220,1231,1320,1337,1360,2219];
    let cdr_name_button;
    let xml_name_button;
    if (ids_proveedores_exception.includes(rowsdata.map.id_proveedor)) {
      xml_name_button = 'Seleccionar Factura en XML';
      cdr_name_button = 'Seleccionar GUIA en PDF';
    } else {
      if (rowsdata.map.tipo === 'Servicio') {
        cdr_name_button = 'Seleccionar GUIA en PDF';
        xml_name_button = 'Seleccionar Factura en XML *';
      } else {
        cdr_name_button = 'Seleccionar GUIA en PDF*';
        xml_name_button = 'Seleccionar Factura en XML *';
      }
    }
    

    const formatCurrency = (value, moneda) => {
      let currency = 'PEN';
    
      if (moneda === 'S/') {
          currency = 'PEN';
        }else  if (moneda === 'US$') {
        currency = 'USD';
      }
    
      return value.toLocaleString('es-PE', {
        style: 'currency',
        currency: currency,
      });
    };

      const handlePDF = (e) =>{
        const file = e.target.files[0];
        
        if (file.name.endsWith('.pdf') || file.name.endsWith('.PDF')) {
          setPdf(file);
        } else {
          setSnackbar({ children: 'Debe subir la factura con extensión .pdf', severity: 'error' });

        }
      }

      const handleXML = (e) =>{
        const file = e.target.files[0];

        if (file.name.endsWith('.xml') || file.name.endsWith('.XML')) {
          setXml(file);
        } else {
          setSnackbar({ children: 'Debe subir el XML con extensión .xml', severity: 'error' });
        }
      }

      const handleCDR = (e) =>{
        const file = e.target.files[0];

        if (file.name.endsWith('.xml') || file.name.endsWith('.XML')) {
          setCdr(file);
        } else {
          setSnackbar({ children: 'Debe subir el CDR con extensión .xml', severity: 'error' });
        }
      }
      const handleGUIA = (e) =>{
        const file = e.target.files[0];

        if (file.name.endsWith('.pdf') || file.name.endsWith('.PDF')) {
          setGuia(file);
        } else {
          setSnackbar({ children: 'Debe subir la guia con extensión .pdf', severity: 'error' });
        }
      }
      const handleNCPDF = (e) =>{
        const file = e.target.files[0];

        if (file.name.endsWith('.pdf') || file.name.endsWith('.PDF')) {
          setNcpdf(file);
        } else {
          setSnackbar({ children: 'Debe subir la Nota de Credito con extensión .pdf', severity: 'error' });
        }
      }
      const handleNCXML = (e) =>{
        const file = e.target.files[0];

        if (file.name.endsWith('.xml') || file.name.endsWith('.XML')) {
          setNcxml(file);
        } else {
          setSnackbar({ children: 'Debe subir la Nota de Credito con extensión .xml', severity: 'error' });
        }
      }
      const handleNDPDF = (e) =>{
        const file = e.target.files[0];

        if (file.name.endsWith('.pdf') || file.name.endsWith('.PDF')) {
          setNdpdf(file);
        } else {
          setSnackbar({ children: 'Debe subir la Nota de Debito con extensión .pdf', severity: 'error' });
        }
      }
      const handleNDXML = (e) =>{
        const file = e.target.files[0];

        if (file.name.endsWith('.xml') || file.name.endsWith('.XML')) {
          setNdxml(file);
        } else {
          setSnackbar({ children: 'Debe subir la Nota de Debito con extensión .xml', severity: 'error' });
        }
      }
      const uploadFilesToBlobStorage = async () => {
        if(ids_proveedores_exception.includes(rowsdata.id_proveedor)){
          if(!pdf){
            setSnackbar({ children: 'Falta subir la factura con extensión .pdf', severity: 'error' });
            return
          }
          // if(!cdr){
          //   setSnackbar({ children: 'Falta subir el CDR con extensión .xml', severity: 'error' });
          //   return
          // }
          // if(!xml){
          //   setSnackbar({ children: 'Falta subir el XML con extensión .xml', severity: 'error' });
          //   return
          // }
          // if(!guia){
          //   setSnackbar({ children: 'Falta subir la GUIA con extensión .pdf', severity: 'error' });
          //   return
          // }
        }
      else{
        if(rowsdata.tipo==='Servicio'){
          if(!pdf){
            setSnackbar({ children: 'Falta subir la factura con extensión .pdf', severity: 'error' });
            return
          }
          // if(!cdr){
          //   setSnackbar({ children: 'Falta subir el CDR con extensión .xml', severity: 'error' });
          //   return
          // }
          if(!xml){
            setSnackbar({ children: 'Falta subir el XML con extensión .xml', severity: 'error' });
            return
          }
          // if(!guia){
          //   setSnackbar({ children: 'Falta subir la GUIA con extensión .pdf', severity: 'error' });
          //   return
          // }
        }
        else{
          if(!pdf){
            setSnackbar({ children: 'Falta subir la factura con extensión .pdf', severity: 'error' });
            return
          }
          // if(!cdr){
          //   setSnackbar({ children: 'Falta subir el CDR con extensión .xml', severity: 'error' });
          //   return
          // }
          if(!xml){
            setSnackbar({ children: 'Falta subir el XML con extensión .xml', severity: 'error' });
            return
          }
          if(!guia){
            setSnackbar({ children: 'Falta subir la GUIA con extensión .pdf', severity: 'error' });
            return
          }  
        }
      }
      const currentDate = new Date();
      const formattedDate = currentDate.toLocaleString('es-ES', {
          year: 'numeric',
          month: '2-digit',
          day: '2-digit',
          hour: '2-digit',
          minute: '2-digit',
          second: '2-digit'
      }).replace(/[\s/:,]/g, '');
      
      // Creación de los archivos con base64 y nombres
      const files = [
          { "base64": pdf, "nombre_del_archivo": rowsdata.map.numero_documento_orden + formattedDate + ".pdf" },
          { "base64": xml, "nombre_del_archivo": rowsdata.map.numero_documento_orden + formattedDate + ".XML" },
          { "base64": cdr, "nombre_del_archivo": rowsdata.map.numero_documento_orden + formattedDate + ".XML" },
          { "base64": guia, "nombre_del_archivo": rowsdata.map.numero_documento_orden + formattedDate + ".pdf" },
          { "base64": ncpdf, "nombre_del_archivo": rowsdata.map.numero_documento_orden + formattedDate + ".pdf" },
          { "base64": ncxml, "nombre_del_archivo": rowsdata.map.numero_documento_orden + formattedDate + ".xml" },
          { "base64": ndpdf, "nombre_del_archivo": rowsdata.map.numero_documento_orden + formattedDate + ".pdf" },
          { "base64": ndxml, "nombre_del_archivo": rowsdata.map.numero_documento_orden + formattedDate + ".xml" }
      ];
      
      // Obtener el array de base64
      const base64Array = await Promise.all(
          files.map((file) => {
              return new Promise((resolve, reject) => {
                  if (file.base64) {
                      const reader = new FileReader();
                      reader.onload = () => {
                          const base64String = reader.result.split(',')[1];
                          resolve({ base64: base64String, nombre: file.nombre_del_archivo });
                      };
                      reader.onerror = (error) => {
                          reject(error);
                      };
                      reader.readAsDataURL(file.base64);
                  } else {
                      resolve(null); // Si el archivo no está definido, agregar un valor nulo al array
                  }
              });
          })
      );
      
      Swal.fire({
          title: 'Registro de documentos PDF, XML, CDR y GUIA',
          text: "¿Está conforme de subir documentos?",
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Aceptar',
          cancelButtonText: 'Cancelar'
      }).then((result) => {
          async function handleConfirmedResult() {
              if (result.isConfirmed) {
                  setBoton(true);
              
                  // Crear un array de documentos para enviar
                  const documentos = rowsdata.map((row, i) => ({
                      id_oc_cabecera: row.id_oc_cabecera,
                      id_ne_cabecera: row.id_ne_cabecera,
                      id_proveedor: row.id_proveedor,
                      nombre_factura_pdf: `TT${row.numero_documento}.pdf`,
                      factura_pdf: base64Array[0]?.base64,
                      nombre_factura_xml: `TT${row.numero_documento}.xml`,
                      factura_xml: base64Array[1] ? base64Array[1].base64 : undefined,
                      nombre_factura_cdr: `TT${row.numero_documento}.cdr`,
                      factura_cdr: base64Array[2] ? base64Array[2].base64 : undefined,
                      nombre_factura_guia: `TT${row.numero_documento}.pdf`,
                      facturaGuia: base64Array[3] ? base64Array[3].base64 : undefined,
                      nombre_credito_pdf: `NC${row.numero_documento}.pdf`,
                      credito_pdf: base64Array[4] ? base64Array[4].base64 : undefined,
                      nombre_credito_xml: `NC${row.numero_documento}.xml`,
                      credito_xml: base64Array[5] ? base64Array[5].base64 : undefined,
                      nombre_debito_pdf: `ND${row.numero_documento}.pdf`,
                      debito_pdf: base64Array[6] ? base64Array[6].base64 : undefined,
                      nombre_debito_xml: `ND${row.numero_documento}.xml`,
                      debito_xml: base64Array[7] ? base64Array[7].base64 : undefined
                  }));
                  
                  try {
                      // Enviar todos los documentos
                      await ProveedorService.crearDocumentosNEMasivo(documentos, setBoton, user.token);
                      setEnviar('Se registraron correctamente todos los documentos');
                  } catch (error) {
                      console.error('Error al crear los documentos:', error);
                      setEnviar('Hubo un error al registrar');
                  }
              }
          }
          handleConfirmedResult();
      });      
      };
      const handleClickRegresar = () => {
        history.push(`/facturaspendienteadjuntarproveedor`);
        history.go(0);
      }
      useEffect(() => {
        if(rowsdata.id_proveedor && user){
             if(user.id_usuario != rowsdata.id_proveedor){
              history.push('/inicio');
              history.go();
            }
        }
      }, [rowsdata])
      const columns = [
        { field: "oc_documento", headerName: "Orden SAP", width: 150 },
        { field: "ne_documento", headerName: "NE SAP", width: 150 },
        { field: "observaciones", headerName: "Comentario", width: 350 },
        { field: "total", headerName: "Total", width: 150,
          valueFormatter: (params) => formatCurrency(params.value,rowsdata.moneda)}
      ];
      
 return (
    <Grid width={'100%'} m="auto">
    <Grid container justifyContent="flex-start" alignItems="center" spacing={2} sx={{py:1}}>
        <Grid container item xs={12} sm={12} >
                <Grid item sm={12}>
                
                    {/* <CMLabel sx={{ margin: 2 }} fontSize="20px" color="black"><b>Numero de Orden de {rowsdata.tipo === "Articulo" ? "Compra" : "Servicio"} #{rowsdata.numero_documento_orden}</b></CMLabel> */}
                    
                </Grid>
                <Grid item sm={6}>
                
                    {/* <CMLabel sx={{ margin: 2 }} fontSize='20px' color="black"><b>Numero de Nota de entrega {rowsdata.tipo==='Articulo' ? 'Compra' : 'Servicio'} #{rowsdata.numero_documento}</b></CMLabel> */}
                    
                </Grid>
                <Grid item sm={6} container justifyContent="flex-end" alignItems="center" >
                  <Grid container xs={2} justifyContent="flex-end" alignItems="center" >
                        <Grid item justifyContent="flex-end" alignItems="center" >
                          <CMIconButton disableRipple={true} size="large" sx={{color: "primary.main", display: "block"}} onClick={handleClickRegresar}>
                            <ArrowBackIcon/>
                            <Typography sx={{lineHeight: 0}}>Regresar</Typography>
                          </CMIconButton>
                        </Grid>
                  </Grid>
                </Grid>
        </Grid>
        <Grid container item xs={12} sm={12}>
                <Grid item sm={12}>
                    <CMLabel sx={{ margin: 2 }} fontSize='20px' color="black"><b>Proveedor</b></CMLabel>
                    <hr></hr>
                    <Grid item sm={12}>
                    {Object.keys(groupedRows).map((key, index) => {const groupedItems = groupedRows[key];const item = groupedItems[0];return (
                        <CMLabel key={index} fontSize='16px' color='black'><b>Nombre :</b> {item.nombre_proveedor}</CMLabel>);})}    
                    </Grid>
                    <br></br>
                </Grid>
                <Grid item sm={6.5}>
                    <CMLabel sx={{ margin: 2 }} fontSize='20px' color="black"><b>Detalle Orden de {rowsdata.tipo==='Articulo' ? 'Compra' : 'Servicio'}</b></CMLabel>
                    <hr></hr>
                    <Grid item sm={12}>
                    {Object.keys(groupedRows).map((key, index) => {const groupedItems = groupedRows[key];const item = groupedItems[0];return (
                        <CMLabel key={index}  fontSize='14px' color='black'><b>Condición de Pago :</b> {item.condic_pago}</CMLabel>);})} 
                    </Grid>
                    <br></br>
                    <div style={{ height: 400, width: "100%" }}>
      <DataGrid
        rows={rows2}
        columns={columns}
        pageSize={8}
        rowsPerPageOptions={[8]}
      />
    </div>
                </Grid>
                <Grid item sm={0.5}>
                  </Grid>
                <Grid item sm={5}>
                    <CMLabel sx={{ margin: 2 }} fontSize='20px' color="black"><b>Importe</b></CMLabel>
                    <hr></hr>
                    <Grid item sm={6} container justifyContent="space-between" alignItems="center" >
                      <Grid item sm={12} container justifyContent="space-between">
                          <Grid>
                          <CMLabel fontSize='16px' color='black'><b>Subtotal :</b></CMLabel>
                          </Grid>
                          <Grid>
                          <CMLabel fontSize='16px' color='black'> { SumaSinIGV? formatCurrency(SumaSinIGV,rowsdata.moneda) : formatCurrency(0,rowsdata.moneda)}</CMLabel>
                          </Grid>
                      </Grid>
                      <Grid item sm={12}  container justifyContent="space-between">
                          <Grid>
                          <CMLabel fontSize='16px' color='black'><b>Impuesto :</b></CMLabel>
                          </Grid>
                          <Grid>
                          <CMLabel fontSize='16px' color='black'>  { SumaIGV ? formatCurrency(SumaIGV,rowsdata.moneda) : formatCurrency(0,rowsdata.moneda)}</CMLabel>
                          </Grid>
                      </Grid>
                      <Grid item sm={12}  container justifyContent="space-between">
                        <Grid>
                        <CMLabel fontSize='16px' color='black'><b>Total :</b></CMLabel>
                        </Grid>
                        <Grid>
                        <CMLabel fontSize='16px' color='black'> { formatCurrency(sumaTotal,rowsdata.moneda) }</CMLabel>
                        </Grid>
                      </Grid>
                    </Grid>
                    <CMLabel sx={{ margin: 2 }} fontSize='20px' color="black"><b>Adjuntar Archivos</b></CMLabel>
                    <hr></hr>
                    <Grid item container sm={12}>
                    <CMLabel sx={{ margin: 2 }} fontSize='14px' color="black"> Por favor, suba los archivos de la factura en .pdf y el archivo XML que son documentos obligatorios relacionados con su solicitud</CMLabel>

                      <Grid item sm={4}>
                        <Button
                            variant="contained"
                            component="label"
                            sx={{marginTop:"6px",marginBottom:"6px"}}
                          >
                           {!pdf ? 'Seleccionar Factura en PDF *' : 'Se subio correctamente PDF'}
                            <input
                              type="file"
                              onChange={handlePDF}
                              hidden
                            />
                          </Button>
                        </Grid>
                          {rowsdata.factura_pdf &&
                          <Grid item sm={4}>
                          <a href={rowsdata.factura_pdf} target="_blank" rel="noopener noreferrer" download>
                          <Icon>
                            <CloudDownloadIcon/>
                          </Icon>
                          PDF
                          </a>
                          </Grid>
                          }
                    </Grid>
                    <Grid item container sm={12}>
                          <Grid item sm={4}>
                          <Button
                            variant="contained"
                            component="label"
                            sx={{marginTop:"6px",marginBottom:"6px"}}
                          >
                            {!xml ? xml_name_button : 'Se subio correctamente XML'}
                            <input
                              type="file"
                              onChange={handleXML}
                              hidden
                            />
                          </Button>
                          </Grid>
                          {rowsdata.factura_xml &&
                            <Grid item sm={4}>
                            <a href={rowsdata.factura_xml} target="_blank" rel="noopener noreferrer" download>
                            <Icon>
                              <CloudDownloadIcon/>
                            </Icon>
                            XML
                            </a>
                            </Grid>
                          }
                    </Grid>
                    <Grid item container sm={12}>
                          <Grid item sm={4}>
                            <Button
                              variant="contained"
                              component="label"
                              sx={{marginTop:"6px",marginBottom:"6px"}}
                            >
                            {!cdr ? 'Seleccionar CDR en XML' : 'Se subio correctamente CDR'}
                              <input
                                type="file"
                                onChange={handleCDR}
                                hidden
                              />
                            </Button>
                          </Grid>
                          {rowsdata.factura_cdr &&
                            <Grid item sm={4}>
                            <a href={rowsdata.factura_cdr} target="_blank" rel="noopener noreferrer" download>
                            <Icon>
                              <CloudDownloadIcon/>
                            </Icon>
                            CDR
                            </a>
                            </Grid>
                          }
                    </Grid>
                    <Grid item container sm={12}>
                        <Grid item sm={4}>
                        <Button
                            variant="contained"
                            component="label"
                            sx={{marginTop:"6px",marginBottom:"6px"}}
                          >
                           {!guia ? cdr_name_button : 'Se subio correctamente la GUIA'}
                            <input
                              type="file"
                              onChange={handleGUIA}
                              hidden
                            />
                          </Button>
                        </Grid>
                          {rowsdata.factura_guia &&
                          <Grid item sm={4}>
                          <a href={rowsdata.factura_guia} target="_blank" rel="noopener noreferrer" download>
                          <Icon>
                            <CloudDownloadIcon/>
                          </Icon>
                          GUIA
                          </a>
                          </Grid>
                          }
                    </Grid>
                    <Grid item container sm={12}>
                        <Grid item sm={4}>
                        <Button
                            variant="contained"
                            component="label"
                            sx={{marginTop:"6px",marginBottom:"6px"}}
                          >
                           {!ncpdf ? 'Seleccionar Nota de Crédito en PDF' : 'Se subio correctamente la NC'}
                            <input
                              type="file"
                              onChange={handleNCPDF}
                              hidden
                            />
                          </Button>
                        </Grid>
                          {rowsdata.credito_pdf &&
                          <Grid item sm={4}>
                          <a href={rowsdata.credito_pdf} target="_blank" rel="noopener noreferrer" download>
                          <Icon>
                            <CloudDownloadIcon/>
                          </Icon>
                          CREDITOPDF
                          </a>
                          </Grid>
                          }
                    </Grid>
                    <Grid item container sm={12}>
                        <Grid item sm={4}>
                        <Button
                            variant="contained"
                            component="label"
                            sx={{marginTop:"6px",marginBottom:"6px"}}
                          >
                           {!ncxml ? 'Seleccionar Nota de Crédito en XML' : 'Se subio correctamente la NC'}
                            <input
                              type="file"
                              onChange={handleNCXML}
                              hidden
                            />
                          </Button>
                        </Grid>
                          {rowsdata.credito_xml &&
                          <Grid item sm={4}>
                          <a href={rowsdata.credito_xml} target="_blank" rel="noopener noreferrer" download>
                          <Icon>
                            <CloudDownloadIcon/>
                          </Icon>
                          CREDITOXML
                          </a>
                          </Grid>
                          }
                    </Grid>
                    <Grid item container sm={12}>
                        <Grid item sm={4}>
                        <Button
                            variant="contained"
                            component="label"
                            sx={{marginTop:"6px",marginBottom:"6px"}}
                          >
                           {!ndpdf ? 'Seleccionar Nota de Débito en PDF' : 'Se subio correctamente la ND'}
                            <input
                              type="file"
                              onChange={handleNDPDF}
                              hidden
                            />
                          </Button>
                        </Grid>
                          {rowsdata.debito_pdf &&
                          <Grid item sm={4}>
                          <a href={rowsdata.debito_pdf} target="_blank" rel="noopener noreferrer" download>
                          <Icon>
                            <CloudDownloadIcon/>
                          </Icon>
                          DEBITOPDF
                          </a>
                          </Grid>
                          }
                    </Grid>
                    <Grid item container sm={12}>
                        <Grid item sm={4}>
                        <Button
                            variant="contained"
                            component="label"
                            sx={{marginTop:"6px",marginBottom:"6px"}}
                          >
                           {!ndxml ? 'Seleccionar Nota de Débito en XML' : 'Se subio correctamente la ND'}
                            <input
                              type="file"
                              onChange={handleNDXML}
                              hidden
                            />
                          </Button>
                        </Grid>
                          {rowsdata.debito_xml &&
                          <Grid item sm={4}>
                          <a href={rowsdata.debito_xml} target="_blank" rel="noopener noreferrer" download>
                          <Icon>
                            <CloudDownloadIcon/>
                          </Icon>
                          DEBITOXML
                          </a>
                          </Grid>
                          }
                    </Grid>
                </Grid>
        </Grid>
        <Grid container item xs={12} sm={12}>
  <Grid >
  {}
     {!!snackbar && (
                    <Snackbar
                    open
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                    onClose={handleCloseSnackbar}
                    autoHideDuration={6000}
                    >
                    <Alert {...snackbar} onClose={handleCloseSnackbar} />
                    </Snackbar>
                )}
  </Grid>
  <Grid item xs={12} sm={12} container>
    <Grid item xs={12} sm={6} container alignItems="center">
        {}
    </Grid>
    <Grid item xs={12} sm={0.5} container>

    </Grid>
    
    <Grid item xs={12} sm={3} container>
    <CMButton
      label="Registrar Documentos"
      variant="contained"
      sx={{ marginTop: '10px' }}
      onClick={uploadFilesToBlobStorage}
      disabled={boton}
    />
    </Grid>
    
  </Grid>
</Grid>
    </Grid>
    </Grid>
 )
}

export default FacturasPendientesAdjuntarMasivoVer