import axios from "axios";
import {urlAzure} from "../config";
export async function getOCListProveedor(datalist, setValue, token) {
    var data = JSON.stringify(datalist);
    var config = {
      method: 'post',
      url: `${urlAzure}api/OC/listProveedor`,
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      },
      data: data
    };
    try {
      const response = await axios(config);
      // Agregar el campo "id" a los datos de respuesta
      const dataWithIds = response.data.map((item, index) => ({ ...item, id: index + 1 }));
      setValue(dataWithIds);
    } catch (error) {
      // Manejar el error aquí
      console.error(error);
    }
  }
  export async function getOCListProveedorExcel(datalist, setValue, token) {
    var data = JSON.stringify(datalist);
    var config = {
      method: 'post',
      url: `${urlAzure}api/OC/listProveedorExport`,
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      },
      data: data
    };
    try {
      const response = await axios(config);
      // Agregar el campo "id" a los datos de respuesta
      const dataWithIds = response.data.map((item, index) => ({ ...item, id: index + 1 }));
      setValue(dataWithIds);
    } catch (error) {
      // Manejar el error aquí
      console.error(error);
    }
  }

  export async function getListFacturasRechazadasProveedor(dataList,setValue, token) {
    var data = dataList;
    console.log(dataList);
    var config = {
      method: 'post',
      url: `${urlAzure}api/NE/ListFacturasRechazadasProveedor`,
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      },
      data: data
    };
    try {
      const response = await axios(config);
      // Agregar el campo "id" a los datos de respuesta
      const dataWithIds = response.data.map((item, index) => ({ ...item, id: index + 1 }));
      // Sort the data by id_oc_cabecera
      const sortedData = dataWithIds.sort((a, b) => a.id_oc_cabecera - b.id_oc_cabecera);
      setValue(sortedData);
    } catch (error) {
      // Manejar el error aquí
      console.error(error);
    }
  }

  export async function getListFacturasRechazadasProveedorExport(dataList,setValue, token) {
    var data = dataList;
    console.log(dataList);
    var config = {
      method: 'post',
      url: `${urlAzure}api/NE/ListFacturasRechazadasProveedorExport`,
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      },
      data: data
    };
    try {
      const response = await axios(config);
      // Agregar el campo "id" a los datos de respuesta
      const dataWithIds = response.data.map((item, index) => ({ ...item, id: index + 1 }));
      // Sort the data by id_oc_cabecera
      const sortedData = dataWithIds.sort((a, b) => a.id_oc_cabecera - b.id_oc_cabecera);
      setValue(sortedData);
    } catch (error) {
      // Manejar el error aquí
      console.error(error);
    }
  }

  export async function getListFacturasAprobadasProveedor(dataList,setValue, token) {
    var data = dataList;
    console.log(dataList);
    var config = {
      method: 'post',
      url: `${urlAzure}api/NE/ListFacturasAprobadasProveedor`,
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      },
      data: data
    };
    try {
      const response = await axios(config);
      // Agregar el campo "id" a los datos de respuesta
      const dataWithIds = response.data.map((item, index) => ({ ...item, id: index + 1 }));
      // Sort the data by id_oc_cabecera
      const sortedData = dataWithIds.sort((a, b) => a.id_oc_cabecera - b.id_oc_cabecera);
      setValue(sortedData);
    } catch (error) {
      // Manejar el error aquí
      console.error(error);
    }
  }

  export async function getListFacturasAprobadasProveedorExport(dataList,setValue, token) {
    var data = dataList;
    console.log(dataList);
    var config = {
      method: 'post',
      url: `${urlAzure}api/NE/ListFacturasAprobadasProveedorExport`,
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      },
      data: data
    };
    try {
      const response = await axios(config);
      // Agregar el campo "id" a los datos de respuesta
      const dataWithIds = response.data.map((item, index) => ({ ...item, id: index + 1 }));
      // Sort the data by id_oc_cabecera
      const sortedData = dataWithIds.sort((a, b) => a.id_oc_cabecera - b.id_oc_cabecera);
      setValue(sortedData);
    } catch (error) {
      // Manejar el error aquí
      console.error(error);
    }
  }

  export async function crearConformidad(datalist,setSnackbar,setLoading,setBtndisabled, token) {
    var data = JSON.stringify(datalist);
    var config = {
      method: 'post',
      url: `${urlAzure}api/OC/crearConformidad`,
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      },
      data: data
    };
    try {
      const response = await axios(config);
      setLoading(false);
      setBtndisabled(true);
      setSnackbar({ children: response.data ? 'Se registro correctamente' : 'Error en registrar', severity: response.data ? 'success' : 'error' });

    } catch (error) {
      setLoading(false);
      setBtndisabled(false);
      setSnackbar({ children: error, severity: 'error' });

    }
  }
  export async function crearDocumentosOC(datalist,setBoton, token) {
    var data = JSON.stringify(datalist);
    var config = {
      method: 'post',
      url: `${urlAzure}api/NE/adjuntarFactura`,
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      },
      data: data
    };
    try {
      const response = await axios(config);
     
      setBoton(true);
    } catch (error) {
      // Manejar el error aquí
      console.error(error);
     setBoton(false);
    
    }
  }

  export async function crearDocumentosNEMasivo(datalist,setBoton, token) {
    var data = JSON.stringify(datalist);
    var config = {
      method: 'post',
      url: `${urlAzure}api/NE/adjuntarFacturamasiva`,
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      },
      data: data
    };
    try {
      const response = await axios(config);
     
      setBoton(true);
    } catch (error) {
      // Manejar el error aquí
      console.error(error);
     setBoton(false);
    
    }
  }

  export async function getPendienteAdjuntarArchivoFactura(datalist, setValue, token) {
    var data = JSON.stringify(datalist);
    var config = {
      method: 'post',
      url: `${urlAzure}api/NE/listProveedorPendienteFactura`,
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      },
      data: data
    };
    try {
      const response = await axios(config);
      // Agregar el campo "id" a los datos de respuesta
      const dataWithIds = response.data.map((item, index) => ({ ...item, id: index + 1 }));
      setValue(dataWithIds);
    } catch (error) {
      // Manejar el error aquí
      console.error(error);
    }
  }
  export async function getPendienteAdjuntarArchivoFacturaExcel(datalist, setValue, token) {
    var data = JSON.stringify(datalist);
    var config = {
      method: 'post',
      url: `${urlAzure}api/NE/listProveedorPendienteFacturaExport`,
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      },
      data: data
    };
    try {
      const response = await axios(config);
      // Agregar el campo "id" a los datos de respuesta
      const dataWithIds = response.data.map((item, index) => ({ ...item, id: index + 1 }));
      setValue(dataWithIds);
    } catch (error) {
      // Manejar el error aquí
      console.error(error);
    }
  }


  export async function getPendienteAdjuntarArchivoFacturaSolicitante(datalist, setValue, token,setLoading) {
    var data = JSON.stringify(datalist);
    var config = {
      method: 'post',
      url: `${urlAzure}api/NE/listSolicitantePendienteFactura`,
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      },
      data: data
    };
    try {
      const response = await axios(config);
      // Agregar el campo "id" a los datos de respuesta
      const dataWithIds = response.data.map((item, index) => ({ ...item, id: index + 1 }));
      setValue(dataWithIds);
      setLoading(false);
    } catch (error) {
      // Manejar el error aquí
      console.error(error);
    }
  }
  export async function getPendienteAdjuntarArchivoFacturaExcelSolicitante(datalist, setValue, token,setBtndisabled) {
    var data = JSON.stringify(datalist);
    var config = {
      method: 'post',
      url: `${urlAzure}api/NE/listSolicitantePendienteFacturaExport`,
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      },
      data: data
    };
    try {
      const response = await axios(config);
      // Agregar el campo "id" a los datos de respuesta
      const dataWithIds = response.data.map((item, index) => ({ ...item, id: index + 1 }));
      setValue(dataWithIds);
      setBtndisabled(false);
    } catch (error) {
      // Manejar el error aquí
      console.error(error);
    }
  }


  export async function getEnviarPDF(id,token) {
    var data = '';
    var config = {
      method: 'get',
      url: `${urlAzure}api/NE/enviarFacturaPdf/${id}`,
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      },
      data: data
    };
    try {
      const response = await axios(config);
      // Agregar el campo "id" a los datos de respuesta
    //   const dataWithIds = response.data.map((item, index) => ({ ...item, id: index + 1 }));
      return response.data;
    } catch (error) {
      // Manejar el error aquí
      console.error(error);
    }
  }


  export async function getListHistorialNotaEntrega(datalist, setValue, token) {
    var data = JSON.stringify(datalist);
    var config = {
      method: 'post',
      url: `${urlAzure}api/NE/listHistorialProveedor`,
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      },
      data: data
    };
    try {
      const response = await axios(config);
      // Agregar el campo "id" a los datos de respuesta
      const dataWithIds = response.data.map((item, index) => ({ ...item, id: index + 1 }));
      // Sort the data by id_oc_cabecera
      const sortedData = dataWithIds.sort((a, b) => a.id_oc_cabecera - b.id_oc_cabecera);
      setValue(sortedData);
    } catch (error) {
      // Manejar el error aquí
      console.error(error);
    }
  }
  
  export async function getListHistorialNotaEntregaExcel(datalist, setValue, token) {
    var data = JSON.stringify(datalist);
    var config = {
      method: 'post',
      url: `${urlAzure}api/NE/listHistorialProveedorExport`,
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      },
      data: data
    };
    try {
      const response = await axios(config);
      // Agregar el campo "id" a los datos de respuesta
      const dataWithIds = response.data.map((item, index) => ({ ...item, id: index + 1 }));
      // Sort the data by id_oc_cabecera
      const sortedData = dataWithIds.sort((a, b) => a.id_oc_cabecera - b.id_oc_cabecera);
      setValue(sortedData);
    } catch (error) {
      // Manejar el error aquí
      console.error(error);
    }
  }
  

  export async function getListPendienteConformidadProveedor(datalist, setValue, token) {
    var data = JSON.stringify(datalist);
    var config = {
      method: 'post',
      url: `${urlAzure}api/OC/listProveedorPendienteConformidad`,
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      },
      data: data
    };
    try {
      const response = await axios(config);
      // Agregar el campo "id" a los datos de respuesta
      const dataWithIds = response.data.map((item, index) => ({ ...item, id: index + 1 }));
      // Sort the data by id_oc_cabecera
      const sortedData = dataWithIds.sort((a, b) => a.id_oc_cabecera - b.id_oc_cabecera);
      setValue(sortedData);
    } catch (error) {
      // Manejar el error aquí
      console.error(error);
    }
  }
  
  export async function getListPendienteConformidadProveedorExcel(datalist, setValue, token) {
    var data = JSON.stringify(datalist);
    var config = {
      method: 'post',
      url: `${urlAzure}api/OC/listProveedorPendienteConformidadExport`,
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      },
      data: data
    };
    try {
      const response = await axios(config);
      // Agregar el campo "id" a los datos de respuesta
      const dataWithIds = response.data.map((item, index) => ({ ...item, id: index + 1 }));
      // Sort the data by id_oc_cabecera
      const sortedData = dataWithIds.sort((a, b) => a.id_oc_cabecera - b.id_oc_cabecera);
      setValue(sortedData);
    } catch (error) {
      // Manejar el error aquí
      console.error(error);
    }
  }
  