import axios from "axios";
import {urlAzure} from "../config";
export async function getOCID(setValue, id,token) {
    var data = '';
    var config = {
      method: 'get',
      url: `${urlAzure}api/OC/list/${id}`,
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      },
      data: data
    };
    try {
      const response = await axios(config);
      // Agregar el campo "id" a los datos de respuesta
    //   const dataWithIds = response.data.map((item, index) => ({ ...item, id: index + 1 }));
      setValue(response.data);
    } catch (error) {
      // Manejar el error aquí
      console.error(error);
    }
  }


  export async function getOCIDCREARNE(setValue, id,token) {
    var data = '';
    var config = {
      method: 'get',
      url: `${urlAzure}api/OC/list/${id}`,
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      },
      data: data
    };
    try {
      const response = await axios(config);
      // Agregar el campo "id" a los datos de respuesta
    //   const dataWithIds = response.data.map((item, index) => ({ ...item, id: index + 1 }));
    const ocDetallesFiltrados = response.data.oc_detalles.filter(detalle => detalle.statusline !== "2");
    const ocDataFiltrado = {
      ...response.data,
      oc_detalles: ocDetallesFiltrados
    };
      setValue(ocDataFiltrado);
    } catch (error) {
      // Manejar el error aquí
      console.error(error);
    }
  }
  export async function getNEID(setValue, id,numero,token) {
    var data = '';
    var config = {
      method: 'get',
      url: `${urlAzure}api/NE/list2/${id}/${numero}`,
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      },
      data: data
    };
    try {
      const response = await axios(config);
      // Agregar el campo "id" a los datos de respuesta
    //   const dataWithIds = response.data.map((item, index) => ({ ...item, id: index + 1 }));
  
      setValue(response.data);
    } catch (error) {
      // Manejar el error aquí
      console.error(error);
    }
  }

  export async function getNEID2(setValue, id,token) {
    var data = '';
    var config = {
      method: 'get',
      url: `${urlAzure}api/NE/list3/${id}`,
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      },
      data: data
    };
    try {
      const response = await axios(config);
      // Agregar el campo "id" a los datos de respuesta
    //   const dataWithIds = response.data.map((item, index) => ({ ...item, id: index + 1 }));
  
      setValue(response.data);
    } catch (error) {
      // Manejar el error aquí
      console.error(error);
    }
  }

  export async function getNEIDMasivo(setValue, ids, token) {
    var data = '';
    var config = {
      method: 'get',
      url: `${urlAzure}api/NE/getNEMasivo/${ids}`, // Cambiamos a usar "ids" que es una cadena de IDs separados por comas
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      },
      data: data
    };
    try {
      const response = await axios(config);
      setValue(response.data);
    } catch (error) {
      // Manejar el error aquí
      console.error(error);
    }
}


  
 

  export async function getListProveedores(setValue,token) {
    var data = '';
    var config = {
      method: 'get',
      url: `${urlAzure}api/Proveedor/list`,
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      },
      data: data
    };
    try {
      const response = await axios(config);
      // Agregar el campo "id" a los datos de respuesta
    //   const dataWithIds = response.data.map((item, index) => ({ ...item, id: index + 1 }));
      setValue(response.data);
    } catch (error) {
      // Manejar el error aquí
      console.error(error);
    }
  }
  export async function getListPersonaContacto(setValue,token) {
    var data = '';
    var config = {
      method: 'get',
      url: `${urlAzure}api/EmpleadoVenta/list`,
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      },
      data: data
    };
    try {
      const response = await axios(config);
      // Agregar el campo "id" a los datos de respuesta
    //   const dataWithIds = response.data.map((item, index) => ({ ...item, id: index + 1 }));
      setValue(response.data);
    } catch (error) {
      // Manejar el error aquí
      console.error(error);
    }
  }
  export async function getListCondicionPago(setValue,token) {
    var data = '';
    var config = {
      method: 'get',
      url: `${urlAzure}api/CondicionCredito/list`,
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      },
      data: data
    };
    try {
      const response = await axios(config);
      // Agregar el campo "id" a los datos de respuesta
    //   const dataWithIds = response.data.map((item, index) => ({ ...item, id: index + 1 }));
      setValue(response.data);
    } catch (error) {
      // Manejar el error aquí
      console.error(error);
    }
  }

  export async function getListProducts(setValue,token) {
    var data = '';
    var config = {
      method: 'get',
      url: `${urlAzure}api/Producto/listOC`,
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      },
      data: data
    };
    try {
      const response = await axios(config);
      // Agregar el campo "id" a los datos de respuesta
    //   const dataWithIds = response.data.map((item, index) => ({ ...item, id: index + 1 }));
      setValue(response.data);
    } catch (error) {
      // Manejar el error aquí
      console.error(error);
    }
  }

  
  export async function getListAlmacen(setValue,token) {
    var data = '';
    var config = {
      method: 'get',
      url: `${urlAzure}api/Almacen/list`,
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      },
      data: data
    };
    try {
      const response = await axios(config);
      // Agregar el campo "id" a los datos de respuesta
    //   const dataWithIds = response.data.map((item, index) => ({ ...item, id: index + 1 }));
      setValue(response.data);
    } catch (error) {
      // Manejar el error aquí
      console.error(error);
    }
  }
  export async function getListCecos(setValue, token) {
    var data = '';
    var config = {
      method: 'get',
      url: `${urlAzure}api/Ceco/list`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      data: data,
    };
    try {
      const response = await axios(config);
      setValue(response.data);
    } catch (error) {
      // Manejar el error aquí
      console.error(error);
    }
  }
  
  
  
  export async function getListDestino(setValue, token) {
    var data = '';
    var config = {
      method: 'get',
      url: `${urlAzure}api/DireccionDestino/list`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      data: data,
    };
    try {
      const response = await axios(config);
      setValue(response.data);
    } catch (error) {
      // Manejar el error aquí
      console.error(error);
    }
  }
  
  
  export async function getCecosId(id, token) {
    try {
      var datos = '';
      const config = {
        method: 'get',
        url: `${urlAzure}api/Ceco/list/${id}`,
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        data: datos,
      };
  
      const response = await axios(config);
      const data = response.data;
  
      // Obtener los valores de codigo_departamente y codigo_seccion de la respuesta del API
      const codigo_departamente = data.codigo_departamente;
      const codigo_seccion = data.codigo_seccion;
      // Retornar los valores
      return {
        codigo_departamente,
        codigo_seccion
      };
    } catch (error) {
      // Manejar el error aquí
      console.error('Error al obtener los valores de codigo_departamente y codigo_seccion:', error);
      return null;
    }
  }

  export async function getListCuentaContable(setValue, token) {
    var data = '';
    var config = {
      method: 'get',
      url: `${urlAzure}api/Cuenta/list`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      data: data,
    };
    try {
      const response = await axios(config);
      setValue(response.data);
    } catch (error) {
      // Manejar el error aquí
      console.error(error);
    }
  }
  export async function getCuentaContableId(id, token) {
    try {
      var datos = '';
      const config = {
        method: 'get',
        url: `${urlAzure}api/Cuenta/list/${id}`,
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        data: datos,
      };
  
      const response = await axios(config);
      const data = response.data;
  
      // Obtener los valores de codigo_departamente y codigo_seccion de la respuesta del API
      const codigo_departamente = data.codigo_departamente;
      const codigo_seccion = data.codigo_seccion;
      // Retornar los valores
      return {
        codigo_departamente,
        codigo_seccion
      };
    } catch (error) {
      // Manejar el error aquí
      console.error('Error al obtener los valores de codigo_departamente y codigo_seccion:', error);
      return null;
    }
  }

  export async function insertarSolPe(datos, setLoadingGrid,setSnackbar,token) {
    try {
      var data = JSON.stringify(datos);
      var config = {
        method: 'post',
        url: `${urlAzure}api/OC/createSolPed`,
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        data: data
      };
      await axios(config);
      setSnackbar({ children: 'Se insertó correctamente', severity: 'success' });
    } catch (error) {
      setSnackbar({ children: 'Ocurrió un error al insertar los datos'+error, severity: 'error' });
      return;
      // Aquí puedes manejar el error como desees, por ejemplo, mostrar un mensaje al usuario o realizar alguna otra acción.
    } finally {
      // Aquí puedes realizar alguna acción adicional después de la solicitud, si es necesario.
      setLoadingGrid(false);
    }
  }
  
  export async function getListControllerOC(datalist,setLoading, setValue, token) {
    var data = JSON.stringify(datalist);
    var config = {
      method: 'post',
      url: `${urlAzure}api/OC/listController`,
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      },
      data: data
    };
    try {
      const response = await axios(config);
      // Agregar el campo "id" a los datos de respuesta
      const dataWithIds = response.data.map((item, index) => ({ ...item, id: index + 1 }));
      // Sort the data by id_oc_cabecera
      const sortedData = dataWithIds.sort((a, b) => a.id_oc_cabecera - b.id_oc_cabecera);
      setValue(sortedData);
      setLoading(false);
    } catch (error) {
      // Manejar el error aquí
      console.error(error);
    }
  }
  
  export async function getListControllerOCHistorialExcel(datalist,setBtndisabled, setValue, token) {
    var data = JSON.stringify(datalist);
    var config = {
      method: 'post',
      url: `${urlAzure}api/OC/listControllerExport`,
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      },
      data: data
    };
    try {
      const response = await axios(config);
      // Agregar el campo "id" a los datos de respuesta
      const dataWithIds = response.data.map((item, index) => ({ ...item, id: index + 1 }));
      // Sort the data by id_oc_cabecera
      const sortedData = dataWithIds.sort((a, b) => a.id_oc_cabecera - b.id_oc_cabecera);
      setValue(sortedData);
      setBtndisabled(false);
    } catch (error) {
      // Manejar el error aquí
      console.error(error);
    }
  }


  export async function getListControllerOCEstado(datalist,setLoading, setValue, token) {
    var data = JSON.stringify(datalist);
    var config = {
      method: 'post',
      url: `${urlAzure}api/OC/listControllerEstado`,
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      },
      data: data
    };
    try {
      const response = await axios(config);
      // Agregar el campo "id" a los datos de respuesta
      const dataWithIds = response.data.map((item, index) => ({ ...item, id: index + 1 }));
      // Sort the data by id_oc_cabecera
      const sortedData = dataWithIds.sort((a, b) => a.id_oc_cabecera - b.id_oc_cabecera);
      setValue(sortedData);
      setLoading(false)
    } catch (error) {
      // Manejar el error aquí
      console.error(error);
    }
  }
  
  export async function getListControllerOCEstadoHistorialExcel(datalist,setBtndisabled, setValue, token) {
    var data = JSON.stringify(datalist);
    var config = {
      method: 'post',
      url: `${urlAzure}api/OC/listControllerEstadoExport`,
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      },
      data: data
    };
    try {
      const response = await axios(config);
      // Agregar el campo "id" a los datos de respuesta
      const dataWithIds = response.data.map((item, index) => ({ ...item, id: index + 1 }));
      // Sort the data by id_oc_cabecera
      const sortedData = dataWithIds.sort((a, b) => a.id_oc_cabecera - b.id_oc_cabecera);
      setValue(sortedData);
      setBtndisabled(false);
    } catch (error) {
      // Manejar el error aquí
      console.error(error);
    }
  }

  
  export async function getOCListEstado(setValue, token) {
    var data = '';
    var config = {
      method: 'get',
      url: `${urlAzure}api/OCEstado/ListOCEstado`,
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      },
      data: data
    };
  
    try {
      const response = await axios(config);
  
      // Filtrar los id_oc_estado del 1 al 10 y el 25
      const filteredData = response.data.filter(item => {
        const id_oc_estado = item.id_oc_estado;
        return id_oc_estado >= 1 && id_oc_estado <= 15 || id_oc_estado === 25 || id_oc_estado === 29;
      });
  
      // Agregar el campo "id" a los datos filtrados
      const dataWithIds = filteredData.map((item, index) => ({ ...item, id: index + 1 }));
  
      // Agregar el nuevo elemento
      const nuevoElemento = {
        id_oc_estado: 0,
        nombre_oc_estado: "TODOS",
        orden: 0,
        id: dataWithIds.length + 1 // Asignar un nuevo id único
      };
  
      const newData = [...dataWithIds, nuevoElemento];
  
      // Ordenar por la propiedad 'orden'
      newData.sort((a, b) => a.orden - b.orden);
  
      setValue(newData);
    } catch (error) {
      // Manejar el error aquí
      console.error(error);
    }
  }
  
  
  export async function getListControllerOCArea(datalist,setLoading, setValue, token) {
    var data = JSON.stringify(datalist);
    var config = {
      method: 'post',
      url: `${urlAzure}api/OC/listControllerArea`,
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      },
      data: data
    };
    try {
      const response = await axios(config);
      // Agregar el campo "id" a los datos de respuesta
      const dataWithIds = response.data.map((item, index) => ({ ...item, id: index + 1 }));
      // Sort the data by id_oc_cabecera
      const sortedData = dataWithIds.sort((a, b) => a.id_oc_cabecera - b.id_oc_cabecera);
      setValue(sortedData);
      setLoading(false);
    } catch (error) {
      // Manejar el error aquí
      console.error(error);
    }
  }
  
  export async function getListControllerOCHistorialAreaExcel(datalist,setBtndisabled, setValue, token) {
    var data = JSON.stringify(datalist);
    var config = {
      method: 'post',
      url: `${urlAzure}api/OC/listControllerAreaExport`,
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      },
      data: data
    };
    try {
      const response = await axios(config);
      // Agregar el campo "id" a los datos de respuesta
      const dataWithIds = response.data.map((item, index) => ({ ...item, id: index + 1 }));
      // Sort the data by id_oc_cabecera
      const sortedData = dataWithIds.sort((a, b) => a.id_oc_cabecera - b.id_oc_cabecera);
      setValue(sortedData);
      setBtndisabled(false);
    } catch (error) {
      // Manejar el error aquí
      console.error(error);
    }
  }