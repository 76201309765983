import React from 'react'
import moment from 'moment';


import { Grid ,Typography} from "@mui/material";
import {
    DataGrid,
    GridToolbarContainer,
    GridToolbarExportContainer,
    GridCsvExportMenuItem,
  } from '@mui/x-data-grid';
import { useState,useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Icon } from '@mui/material';
import { PictureAsPdf } from '@mui/icons-material';
import VisibilityIcon from '@mui/icons-material/Visibility';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import DeleteIcon from '@mui/icons-material/Delete';

import * as XLSX from 'xlsx';
//Components
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import CMLabel from '../../components/atoms/CMLabel';
import CMDatePicker from '../../components/molecules/CMDatePicker';
import CMButton from '../../components/atoms/CMButton';
import CMIconButton from '../../components/atoms/CMIconButton';

//Constants
import {MomentLocaleEs} from '../../constants/MomentLocaleEs';
//Services
import * as SolicitanteService from '../../services/SolicitanteService';
import CMTextField from '../../components/atoms/CMTextField';

const MySwal = withReactContent(Swal)


const SolicitanteOrdenes = () => {
    const [rowsdata, setRowsdata] = useState([]);
    const [rowsexcel, setRowsexcel] = useState([]);
    const [sortModel, setSortModel] = useState([]); // Add sortModel state
    const [filtro,setFiltro] = useState('');

    const [fechaInicio, setFechaInicio] = useState(moment().locale('es', MomentLocaleEs).subtract(10, 'days'));
    const [fechaFin, setFechaFin] = useState(moment().locale('es', MomentLocaleEs));
    const [loading, setLoading] = useState(true);
    const [btndisabled, setBtndisabled] = useState(true);

    const user = JSON.parse(window.localStorage.getItem("user"));
    const history = useHistory();
    const handleFiltro = (event) => {
      setFiltro(event.target.value);
    }
    useEffect(() => {
        const dataList={
            "id_usuario": user.id_usuario,
            "from_date": fechaInicio,
            "to_date": fechaFin,
            "filters":''
        }
        SolicitanteService.getOCListSolicitante(dataList,setRowsdata,user.token,setLoading);
        SolicitanteService.getOCListSolicitanteExcel(dataList,setRowsexcel,user.token,setBtndisabled);

    }, []);
    const updateOrdenes= () =>{
        const dataList={
            "id_usuario": user.id_usuario,
            "from_date": fechaInicio,
            "to_date": fechaFin,
            "filters":filtro
        }
        setLoading(true);
        setBtndisabled(true);
        SolicitanteService.getOCListSolicitante(dataList,setRowsdata,user.token,setLoading);
        SolicitanteService.getOCListSolicitanteExcel(dataList,setRowsexcel,user.token,setBtndisabled);
    }

    const formatCurrency = (value, moneda) => {
      let currency = 'PEN';
    
      if (moneda === 'S/') {
          currency = 'PEN';
        }else  if (moneda === 'US$') {
        currency = 'USD';
      }
    
      return value.toLocaleString('es-PE', {
        style: 'currency',
        currency: currency,
      });
    };

 
    const handleEliminarClick = (id_oc_cabecera) => {
      // Mostrar el cuadro de confirmación Swal
      Swal.fire({
        title: 'Eliminar Orden de Compra',
        text: '¿Está seguro de que desea eliminar esta Orden de Compra?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Sí, eliminar',
        cancelButtonText: 'Cancelar'
      }).then(async (result) => {
        if (result.isConfirmed) {
          try {
            // Eliminar la Orden de Compra
            const datalist = {
              id_oc_cabecera:id_oc_cabecera,
              id_usuario:user.id_usuario
            }
            const result = await SolicitanteService.eliminarOrdenCompra(datalist,user.token);
            Swal.fire(result.respuesta==='BadRequest'? 'Error':'Success', result.mensaje);
            updateOrdenes();
          } catch (error) {
            console.error('Error al eliminar la Orden de Compra:', error);
            Swal.fire('Error', 'Hubo un error al eliminar la Orden de Compra', 'error');
          }
        }
      });
    };
      const exportToExcel = () => {
        const headers = [
          'ID',
          'OC SAP',
          'RUC',
          'Nombre Proveedor',
          'Tipo',
          'Observaciones',
          'Estado',
          'Nombre Estado',
          'Destino',
          'Condición Pago',
          'Fecha Creación',
          'Fecha Documento',
          'Fecha Contable',
          'Fecha Entrega',
          'Solicitante',
          'Aprobador Gerencia',
          'Aprobador Finanzas',
          'Moneda',
          'Impuesto',
          'Sub Total',
          'importe total',
          'ID OC Detalle',
          'Fecha Entrega Detalle',
          'Descripción',
          'Código Artículo',
          'Cantidad Pedida',
          'Cantidad Entregada',
          'Cantidad Recibida',
          'Precio sin IGV',
          'Precio Unitario',
          'Porcentaje Descuento',
          'IGV',
          'Total Artículo',
          'Unidad Medida'
        ];
    
        const data = [
          headers,
          ...rowsexcel.flatMap(item =>
            item.oc_detalles.map(detalle => {
              const TotalLinea = item.tipo === "Servicio"? detalle.total_servicio : detalle.total_articulo
              const precioUnitario = item.tipo === "Servicio"? detalle.total_servicio : detalle.precio_unitario              ;
              let igvprom;
              if (detalle.include_igv === "EXONERADO") {
                igvprom = 0;
              } else if (detalle.include_igv === "IGV_RES") {
                igvprom = 1.10;
              } else if (detalle.include_igv === "IGV") {
                igvprom = 1.18;
              } else {
                throw new Error(`Porcentaje de descuento no válido: ${detalle.include_igv}`);
              }               
              const precioSinIgv = precioUnitario / igvprom;
              const igv = TotalLinea - (TotalLinea / igvprom);
            return[       
              item.id_oc_cabecera,
              item.numero_documento_orden,
              item.ruc_proveedor,
              item.nombre_proveedor,
              item.tipo,
              item.observaciones,
              item.estado,
              item.ultimo_nombre_oc_estado,
              item.destino,
              item.condic_pago,
              formatDate(item.fecha_registro),
              formatDate(item.fecha_documento),
              formatDate(item.fecha_contable),
              formatDate(item.fecha_entrega),
              item.nombre_solicitante,
              item.nombre_aprobador_gerencia,
              item.nombre_aprobador_finanzas,
              item.moneda,
              item.impuesto,
              item.total_antes_descuento,
              item.total_pago_vencido,
              detalle.id_oc_detalle,
              formatDate(detalle.fecha_entrega),
              detalle.descripcion,
              detalle.codigo_articulo,
              detalle.cantidad_pedida,
              detalle.cantidad_entregada,
              detalle.cantidad_recibida,
              (precioSinIgv || 0).toFixed(2), 
              (precioUnitario || 0).toFixed(2),
              detalle.porcentaje_descuento || 0,
              (igv || 0).toFixed(2),  
              (TotalLinea || 0).toFixed(2),
              detalle.unidad_medida
            ]})
          )
        ];
    
        const worksheet = XLSX.utils.aoa_to_sheet(data);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, 'Ordenes');
    
        const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
        saveAsExcelFile(excelBuffer, 'Ordenes.xlsx');
      };
      const formatDate = (dateString) => {
        const date = new Date(dateString);
        return date.toLocaleDateString('es-ES', { day: '2-digit', month: '2-digit', year: 'numeric' });
      };
    
      const saveAsExcelFile = (buffer, fileName) => {
        const data = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        if (typeof window.navigator.msSaveBlob !== 'undefined') {
          window.navigator.msSaveBlob(data, fileName);
        } else {
          const url = window.URL.createObjectURL(data);
          const link = document.createElement('a');
          link.href = url;
          link.download = fileName;
          link.click();
        }
      };
    const columns = [
      {
        field: "ver", headerName: "Ver", width:50, justifyContent: "center",
        renderCell: (cellValues) => {
          return (
            <CMIconButton onClick={() => {history.push(`/historialordenserviciocreadas/${cellValues.row.id_oc_cabecera}`); history.go(0);}}>
              <VisibilityIcon/>
            </CMIconButton>
          )
        }
      },
      {
        field: "eliminar", headerName: "Eliminar OC", width: 90, justifyContent: "center",
        renderCell: (cellValues) => {
          const isDisabled =
            cellValues.row.ultimo_nombre_oc_estado === 'Nota de Entrega Enviada al Proveedor' ||
            cellValues.row.ultimo_nombre_oc_estado === 'Nota de Entrega Generada' ||
            cellValues.row.ultimo_nombre_oc_estado === 'Documentos Adjuntados por Proveedor' ||
            cellValues.row.ultimo_nombre_oc_estado === 'Documentos Adjuntados enviados a comprobantes' ||
            cellValues.row.ultimo_nombre_oc_estado === 'Documentos Observados' ||
            cellValues.row.ultimo_nombre_oc_estado === 'Factura generada' ||
            cellValues.row.ultimo_nombre_oc_estado === 'Factura con Fecha de pago' ||
            cellValues.row.ultimo_nombre_oc_estado === 'Factura Pagada' || 
            cellValues.row.ultimo_nombre_oc_estado === 'OC/OS importado desde SAP' || 
            cellValues.row.ultimo_nombre_oc_estado === 'NE importado desde SAP' || 
            cellValues.row.ultimo_nombre_oc_estado === 'NE Anulada' || 
            cellValues.row.ultimo_nombre_oc_estado === 'Factura Anulada' || 
            cellValues.row.ultimo_nombre_oc_estado === 'OC/OS Borrador' || 
            cellValues.row.ultimo_nombre_oc_estado === 'Factura Aprobada' || 
            cellValues.row.ultimo_nombre_oc_estado === 'OS/OC Creada' || 
            cellValues.row.ultimo_nombre_oc_estado === 'OS/OC Enviada al Proveedor' || 
            cellValues.row.ultimo_nombre_oc_estado === 'Servicio/Compra recibida parcialmente' || 
            cellValues.row.ultimo_nombre_oc_estado === 'Servicio/Compra recibida completo' || 
            cellValues.row.ultimo_nombre_oc_estado === 'OC/OS Anulada' || 
            cellValues.row.ultimo_nombre_oc_estado === 'Volver a subir documentos' || 
            cellValues.row.ultimo_nombre_oc_estado === 'OS/OC Enviada al Proveedor' ||
            cellValues.row.ultimo_nombre_oc_estado === 'Solicitud de Orden Aprobada Gerencia' ||
            cellValues.row.ultimo_nombre_oc_estado === 'Solicitud de Orden Observada Gerencia' ||
            cellValues.row.ultimo_nombre_oc_estado === 'Solicitud de Orden Rechazada Gerencia' ||
            cellValues.row.ultimo_nombre_oc_estado === 'Solicitud de Orden Aprobada Finanzas' ||
            cellValues.row.ultimo_nombre_oc_estado === 'Solicitud de Orden Observada Finanzas' ||
            cellValues.row.ultimo_nombre_oc_estado === 'Solicitud de Orden Rechazada Finanzas' 
            ;

      
          return (
            <CMIconButton
              onClick={() => {
                if (!isDisabled) {
                  handleEliminarClick(cellValues.row.id_oc_cabecera);
                }
              }}
              disabled={isDisabled}
            >
              <DeleteIcon/>
            </CMIconButton>
          );
        }
      },
      // {
      //   field: "copiar", headerName: "Copiar", width:70, justifyContent: "center",
      //   renderCell: (cellValues) => {
      //     return (
      //       <CMIconButton onClick={() => {history.push(`/copiar${cellValues.row.tipo.toLowerCase()}/${cellValues.row.id_oc_cabecera}`); history.go(0);}}>
      //         <FileCopyIcon/>
      //       </CMIconButton>
      //     )
      //   }
      // },
      {
        field: 'Id',
        headerName: 'Id',
        type: 'text',
        width: 150,
        editable: false,
        valueGetter: (params) => params.row.id_documento_borrador===null || params.row.id_documento_borrador===''  ? '000' +params.row.id_oc_cabecera : params.row.id_documento_borrador  ,
      },

        { field: 'numero_documento_orden', headerName: 'OC SAP', width: 140 },
        { field: 'ruc_proveedor', headerName: 'RUC', type: 'text', width: 120 },
        { field: 'nombre_proveedor', headerName: 'Nombre Proveedor', type: 'text', width: 400 },
        { field: 'observaciones', headerName: 'Observaciones', type: 'text', width: 560, editable: false },
        { field: 'ultimo_nombre_oc_estado', headerName: 'Nombre Estado', type: 'text', width: 290, editable: false },
        { field: 'estado', headerName: 'Estado', type: 'text', width: 120, editable: false },
        { field: 'total_antes_descuento', headerName: 'Sub Total', type: 'number', width: 160, editable: false, valueFormatter: (params) => params.value ? params.value : '',valueGetter: (params) => formatCurrency(params.row.total_antes_descuento,params.row.moneda) },
        { field: 'impuesto', headerName: 'Impuesto', type: 'number', width: 160, editable: false, valueFormatter: (params) => params.value ? params.value : '',valueGetter: (params) => formatCurrency(params.row.impuesto,params.row.moneda)},
        { field: 'total_pago_vencido', headerName: 'importe total', type: 'number', width: 160, editable: false, valueFormatter: (params) => params.value ? params.value : '',valueGetter: (params) => formatCurrency(params.row.total_pago_vencido,params.row.moneda)},
        { field: 'destino', headerName: 'Destino', type: 'text', width: 350, editable: false },
        { field: 'condic_pago', headerName: 'Condición Pago', type: 'text', width: 150, editable: false },
        { field: 'created_at', headerName: 'Fecha Creación', type: 'text', width: 150, editable: false, valueGetter: (params) => {
            const fecha = params.value;
            return moment(fecha).format('DD-MM-YYYY');
          }, },
        { field: 'fecha_documento', headerName: 'Fecha Documento', type: 'text', width: 150, editable: false, valueGetter: (params) => {
            const fecha = params.value;
            return moment(fecha).format('DD-MM-YYYY');
          }, },
        { field: 'fecha_contable', headerName: 'Fecha Contable', type: 'text', width: 150, editable: false, valueGetter: (params) => {
            const fecha = params.value;
            return moment(fecha).format('DD-MM-YYYY');
          },},
        { field: 'fecha_entrega', headerName: 'Fecha Entrega', type: 'text', width: 150, editable: false, valueGetter: (params) => {
            const fecha = params.value;
            return moment(fecha).format('DD-MM-YYYY');
          },},
          { field: 'ultima_fecha_modificacion', headerName: 'OC Fecha Modificación', type: 'text', width: 150, editable: false ,valueGetter: (params) => {
            const fecha = params.value;
            return moment(fecha).format('DD-MM-YYYY');
          },},
          {
            field: 'fecha_aprob',
            headerName: 'Fecha Aprobacion Gerencia',
            type: 'text',
            width: 150,
            editable: false,
            valueGetter: (params) => {
              const tipo = params.row.tipo;
              const fecha = params.value;
              
              if (tipo === 'Servicio') {
                const fechaAprobGerencia = params.row.fecha_aprob_gerencia;
                return fechaAprobGerencia ? moment(fechaAprobGerencia).format('DD-MM-YYYY') : '';
              } else if (tipo === 'Articulo') {
                const fechaAprobFinanzas = params.row.fecha_aprob_finanzas;
                if (fechaAprobFinanzas === null) {
                  return '';
                }
                return moment(fechaAprobFinanzas).format('DD-MM-YYYY');
              }
              return '';
            },},
          { field: 'fecha_aprob_finanzas', headerName: 'Fecha Aprobacion Finanzas',  type: 'text', width: 150, editable: false,
          valueGetter: (params) => { const fecha = params.value;
            if (fecha === null) {
              return '';
            } return moment(fecha).format('DD-MM-YYYY');
          },},
          { field: 'tipo', headerName: 'Tipo', type: 'text', width: 80 },
        { field: 'nombre_solicitante', headerName: 'Solicitante', type: 'text', width: 150, editable: false },
        { field: 'nombre_aprobador_gerencia', headerName: 'Aprobador Gerencia', type: 'text', width: 150, editable: false },
        { field: 'nombre_aprobador_finanzas', headerName: 'Aprobador Finanzas', type: 'text', width: 150, editable: false },
        {
            field: 'orden_pdf',
            headerName: 'Descargar OC',
            width: 150,
            renderCell: (params) => {
              const pdfUrl = params.value;
              return (
                <a href={pdfUrl} target="_blank" rel="noopener noreferrer" download>
                 <Icon>
                    <PictureAsPdf />
                </Icon>
                </a>
              );
            },
          },
      ];
 return (
    <Grid width={'100%'} m="auto">
    <Grid container justifyContent="flex-start" alignItems="center" spacing={2} sx={{py:1}}>
        <Grid container item xs={12} sm={12} >
                <Grid item sm={12} md={6}>
                    <CMLabel sx={{ margin: 2 }} fontSize='28px' color="black"><b>Historial Ordenes Servicio/Compra</b></CMLabel>
                </Grid>
        </Grid>
        <Grid container item xs={12} sm={12} justifyContent="center">
                <Grid item sm={12}>
                    <hr></hr><br></br>
                    <Grid container item xs={12} sm={12} alignItems="center">
                        <Grid item xs={12} sm={2} md={1}>
                            <CMLabel fontSize='14px' color='black'>Fecha inicio creación: </CMLabel>
                        </Grid>
                        <Grid item xs={12} sm={3} md={1.8}>
                            <CMDatePicker 
                                label="Desde"
                                value={fechaInicio}
                                onChange={(newDate) => setFechaInicio(newDate)}
                                readOnly={false}
                                fullWidth
                                disabled={false}
                            />
                        </Grid>
                        <Grid item xs={12} sm={0.2}>

                        </Grid>
                        <Grid item xs={12} sm={2} md={0.8}>
                            <CMLabel fontSize='14px' color='black'  sx={{marginLeft:'10px'}}>Fecha fin creación: </CMLabel>
                        </Grid>
                        <Grid item xs={12} sm={0.2}>

                        </Grid>
                        <Grid item xs={12} sm={3} md={1.8}>
                            <CMDatePicker 
                                label="Hasta"
                                value={fechaFin}
                                onChange={(newDate) => setFechaFin(newDate)}
                                readOnly={false}
                                fullWidth
                                disabled={false}
                            />
                        </Grid>
                        <Grid item xs={12} sm={0.2}>

                        </Grid>
                        <Grid container item sx={12} sm={2} md={0.5}>
                          <CMLabel sx={{ margin: 2 }} fontSize='14px' color="black">Filtro</CMLabel>
                      </Grid>
                      <Grid container item sx={12} sm={3} md={2}>
                        <CMTextField
                            name="filtro"
                            label='fittro'
                            value={filtro}
                            onChange={(e)=>handleFiltro(e)}
                            fullWidth
                          />
                      </Grid>
                        <Grid item xs={12} sm={1.5} md={1.5} mx={{marginTop:'10px'}}>
                            <CMButton
                                label="Consultar"
                                variant="contained"
                                sx={{marginLeft:'10px'}}
                                onClick={updateOrdenes}
                                
                            />
                        </Grid>
                        <Grid item xs={12} sm={2} mx={{marginTop:'10px'}}>
                        <CMButton
                                label="Exportar"
                                variant="contained"
                                sx={{marginLeft:'10px'}}
                                onClick={exportToExcel}
                                disabled={btndisabled}
                            />
                        </Grid>
                     </Grid>
                </Grid>
        </Grid>
        <Grid container item xs={12} sm={12}>
  <Grid style={{ height: 500, width: '100%', overflowX: 'auto' }} sx={{ py: 0 }}>
    <DataGrid
      rows={rowsdata}
      columns={columns}
      pageSize={15}
      rowsPerPageOptions={[15]}
      loading={loading}  // Propiedad que indica si está cargando o no
      sx={{
        height: "100%",
        fontSize: 12,
        "& .MuiDataGrid-window": {
          overflowX: 'scroll',
          "&::-webkit-scrollbar": {
            width: "0.4em",
          },
          "&::-webkit-scrollbar-track": {
            background: "#f1f1f1",
          },
          "&::-webkit-scrollbar-thumb": {
            backgroundColor: "#888",
          },
          "&::-webkit-scrollbar-thumb:hover": {
            background: "#555",
          },
        },
      }}
      pagination
      rowHeight={50}
      sortModel={sortModel}
      onSortModelChange={(model) => setSortModel(model)}
    />
  </Grid>
</Grid>
    </Grid>
    </Grid>
 )
}

export default SolicitanteOrdenes