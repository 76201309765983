

// React
import React from "react";
import { useContext, useState } from "react";
import { BrowserRouter, Switch, Route, Redirect } from "react-router-dom";
import { UserContext } from "../context/UserContext";
//Components
import CMNavbar from "../components/organisms/CMNavbar";
import CMDrawer from "../components/molecules/CMDrawer";
import ProtectedRoute from "./ProtectedRoute";
//Mui
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import Toolbar from '@mui/material/Toolbar';
//Pages
import Login from "../pages/common/Login";
import Inicio from "../pages/common/Inicio";
import OrdenServicioRecibida from "../pages/proveedorordenserviciorecibida/OrdenServicioRecibida";
import SolicitanteOrdenes from "../pages/solicitanteordenes/SolicitanteOrdenes";
import OrdenServicioRecibidaVer from "../pages/proveedorordenserviciorecibida/OrdenServicioRecibidaVer";
import SolicitanteConformidad from "../pages/solicitanteconformidadserviciomercaderia/SolicitanteConformidad";
import SolicitanteAprobadas from "../pages/solicitanteaprobadas/SolicitanteAprobadas";
import SolicitanteConformidadVer from "../pages/solicitanteconformidadserviciomercaderia/SolicitanteConformidadVer";
import SolicitanteAprobadasVer from "../pages/solicitanteaprobadas/SolicitanteAprobadasVer";
import SolicitanteOrdenesVer from "../pages/solicitanteordenes/SolicitanteOrdenesVer";
import CrearOrdenes from "../pages/crearordenes/CrearOrdenesCompra";
import FacturasPendientesAdjuntar from "../pages/facturaspendientesadjuntar/FacturasPendientesAdjuntar";
import FacturasPendientesAdjuntarVer from "../pages/facturaspendientesadjuntar/FacturasPendientesAdjuntarVer";
import FacturasPendientesAdjuntarmasivoVer from "../pages/facturaspendientesadjuntar/FacturasPendientesAdjuntarMasivoVer";
import CrearOrdenesCompra from "../pages/crearordenes/CrearOrdenesCompra";
import CrearOrdenesServicio from "../pages/crearordenes/CrearOrdenesServicio";
import OrdenesPendientesAprobar from "../pages/ordenespendientesaprobaciongerencia/OrdenesPendientesAprobar";
import OrdenesPendientesAprobarVer from "../pages/ordenespendientesaprobaciongerencia/OrdenesPendientesAprobarVer";
import OrdenesPendientesAprobarFinanza from "../pages/ordenespendientesaprobacionfinanzas/OrdenesPendientesAprobarFinanza";
import OrdenesPendientesAprobarFinanzaVer from "../pages/ordenespendientesaprobacionfinanzas/OrdenesPendientesAprobarFinanzaVer";
import OrdenesObservadas from "../pages/ordenesobservadas/OrdenesObservadas";
import OrdenesObservadasVer from "../pages/ordenesobservadas/OrdenesObservadasVer";
import OrdenesRechazadas from "../pages/ordenesrechazadas/OrdenesRechazadas";
import OrdenesRechazadasVer from "../pages/ordenesrechazadas/OrdenesRechazadasVer";
import HistorialOrdenCreadasArea from "../pages/historialordencreadasareagerencia/HistorialOrdenCreadasArea";
import HistorialOrdenCreadasAreaVer from "../pages/historialordencreadasareagerencia/HistorialOrdenCreadasAreaVer";
import HistorialOrdenCreadasFinanzas from "../pages/historialordencreadasfinanzas/HistorialOrdenCreadasFinanzas";
import HistorialOrdenCreadasFinanzasVer from "../pages/historialordencreadasfinanzas/HistorialOrdenCreadasFinanzasVer";
import ListCrearNotaEntrega from "../pages/crearnotaentrega/ListCrearNotaEntrega";
import CrearNotaEntregaVer from "../pages/crearnotaentrega/CrearNotaEntregaVer";
import HistorialNotaEntregaSolicitante from "../pages/historialnotaentregasolicitante/HistorialNotaEntregaSolicitante";
import HistorialNotaEntregaSolicitanteVer from "../pages/historialnotaentregasolicitante/HistorialNotaEntregaSolicitanteVer";
import HistorialNotaEntregaProveedor from "../pages/historialnotaentregasproveedor/HistorialNotaEntregaProveedor";
import HistorialNotaEntregaProveedorVer from "../pages/historialnotaentregasproveedor/HistorialNotaEntregaProveedorVer";
import PendienteOrdenServicioRecibida from "../pages/pendienteproveedorordenserviciorecibida/PendienteOrdenServicioRecibida";
import PendienteOrdenServicioRecibidaVer from "../pages/pendienteproveedorordenserviciorecibida/PendienteOrdenServicioRecibidaVer";
import CopiarCrearOrdenesServicio from "../pages/copiarcrearordenes/CopiarCrearOrdenesServicio";
import CopiarCrearOrdenesCompra from "../pages/copiarcrearordenes/CopiarCrearOrdenesCompra";
import HistorialOrdenController from "../pages/historialcontrollerorden/HistorialOrdenController";
import HistorialOrdenControllerVer from "../pages/historialcontrollerorden/HistorialOrdenControllerVer";
import HistorialNotaEntregaController from "../pages/historialcontrollernotraentrega/HistorialNotaEntregaController";
import HistorialNotaEntregaControllerVer from "../pages/historialcontrollernotraentrega/HistorialNotaEntregaControllerVer";
import FacturasPendientesAdjuntarSolicitante from "../pages/facturaspendientesadjuntarsolicitante/FacturasPendientesAdjuntarSolicitante";
import FacturasPendientesAdjuntarSolicitanteVer from "../pages/facturaspendientesadjuntarsolicitante/FacturasPendientesAdjuntarSolicitanteVer";
import PendienteAprobacionPdfVer from "../pages/pendienteaprobacionpdf/PendienteAprobacionPdfVer";
import PendienteAprobacionPdf from "../pages/pendienteaprobacionpdf/PendienteAprobacionPdf";
import RechazadasPdf from "../pages/pendienteaprobacionpdf/RechazadasPdf";
import AprobacionPdf from "../pages/pendienteaprobacionpdf/AprobacionPdf";
import HistorialOrdenControllerArea from "../pages/historialcontrollerordenarea/HistorialOrdenControllerArea";
import HistorialNotaEntregaControllerArea from "../pages/historialcontrollernotraentregaarea/HistorialNotaEntregaControllerArea";
import HistorialNotaEntregaControllerAreaVer from "../pages/historialcontrollernotraentregaarea/HistorialNotaEntregaControllerAreaVer";
import HistorialOrdenControllerAreaVer from "../pages/historialcontrollerordenarea/HistorialOrdenControllerAreaVer";
import RechazadasFacturacion from "../pages/historialfacturacionordenesolicitante/RechazadasFacturacion";
import RechazadasProveedor from "../pages/historialdocumentosproveedor/RechazadasProveedor";
import AprobadasProveedor from "../pages/historialdocumentosproveedor/AprobadasProveedor";
import AprobacionFacturacion from "../pages/historialfacturacionordenesolicitante/AprobacionFacturacion";


const drawerWidth = 240;

const Router = (props) => {
  const { window } = props;
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const { setUser } = useContext(UserContext);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const container = window !== undefined ? () => window().document.body : undefined;
//Roles
  // 1 Admin
  // 2 Gestor / supervisor
  // 3 Visor
  // 4 Chofer
  // 5 Planer
  return (
    <BrowserRouter>
      <Switch>
        <Route>
            <Route exact path="/" render={() => <Redirect to="/login"/>}/>
            <Route exact path="/login" component={Login}/>
            <Box sx={{ display: 'flex' }}>
              <CssBaseline />
              <CMNavbar handleDrawerToggle={handleDrawerToggle} />
              <Box
                component="nav"
                sx={{ width: { xl: drawerWidth }, flexShrink: { xl: 0 } }}
              >
                <CMDrawer
                  container={container}
                  open={mobileOpen}
                  onClose={handleDrawerToggle}
                />
              </Box>
              <Box
                component="main"
                sx={{ flexGrow: 1, p: 3, width: { xl: `calc(100% - ${drawerWidth}px)` } }}
              >
                
                <Toolbar style={{minHeight:38}}/>
                <ProtectedRoute exact path="/inicio" component={Inicio} roles={[1,2,3,5]}/>

                <ProtectedRoute exact path="/historialordenesrecibidas" component={OrdenServicioRecibida} roles={[1,2,3,5]}/>
                <ProtectedRoute exact path="/historialordenesrecibidas/:ordenid" component={OrdenServicioRecibidaVer} roles={[1,2,3,5]}/>
               
                <ProtectedRoute exact path="/ordenespendientesconfirmar" component={PendienteOrdenServicioRecibida} roles={[1,2,3,5]}/>
                <ProtectedRoute exact path="/ordenespendientesconfirmar/:ordenid" component={PendienteOrdenServicioRecibidaVer} roles={[1,2,3,5]}/>


                <ProtectedRoute exact path="/historialordenserviciocreadas" component={SolicitanteOrdenes} roles={[1,2,3,5]}/>
                <ProtectedRoute exact path="/historialordenserviciocreadas/:ordenid" component={SolicitanteOrdenesVer} roles={[1,2,3,5]}/>
                <ProtectedRoute exact path="/copiarservicio/:ordenid" component={CopiarCrearOrdenesServicio} roles={[1,2,3,5]}/>
                <ProtectedRoute exact path="/copiararticulos/:ordenid" component={CopiarCrearOrdenesCompra} roles={[1,2,3,5]}/>

                
                <ProtectedRoute exact path="/conformidadserviciomercaderia" component={SolicitanteConformidad} roles={[1,2,3,5]}/>
                <ProtectedRoute exact path="/conformidadserviciomercaderia/:ordenid" component={SolicitanteConformidadVer} roles={[1,2,3,5]}/>


                <ProtectedRoute exact path="/ordenesaprobadas" component={SolicitanteAprobadas} roles={[1,2,3,5]}/>
                <ProtectedRoute exact path="/ordenesaprobadas/:ordenid" component={SolicitanteAprobadasVer} roles={[1,2,3,5]}/>

                <ProtectedRoute exact path="/ordenesobservadas" component={OrdenesObservadas} roles={[1,2,3,5]}/>
                <ProtectedRoute exact path="/ordenesobservadas/:ordenid" component={OrdenesObservadasVer} roles={[1,2,3,5]}/>

                <ProtectedRoute exact path="/ordenesrechazadas" component={OrdenesRechazadas} roles={[1,2,3,5]}/>
                <ProtectedRoute exact path="/ordenesrechazadas/:ordenid" component={OrdenesRechazadasVer} roles={[1,2,3,5]}/>

                <ProtectedRoute exact path="/crearordencompra" component={CrearOrdenesCompra} roles={[1,2,3,5]}/>
                <ProtectedRoute exact path="/crearordenservicio" component={CrearOrdenesServicio} roles={[1,2,3,5]}/>


                <ProtectedRoute exact path="/facturaspendienteadjuntarproveedor" component={FacturasPendientesAdjuntar} roles={[1,2,3,5]}/>
                <ProtectedRoute exact path="/facturaspendienteadjuntarproveedor/:ordenid" component={FacturasPendientesAdjuntarVer} roles={[1,2,3,5]}/>
                <ProtectedRoute exact path="/facturaspendienteadjuntarproveedormasivo/:ordenid" component={FacturasPendientesAdjuntarmasivoVer} roles={[1,2,3,5]}/>

                <ProtectedRoute exact path="/ordenespendientesaprobaciongerencia" component={OrdenesPendientesAprobar} roles={[1,2,3,5]}/>
                <ProtectedRoute exact path="/ordenespendientesaprobaciongerencia/:ordenid" component={OrdenesPendientesAprobarVer} roles={[1,2,3,5]}/>

                <ProtectedRoute exact path="/ordenespendientesaprobacionfinanzas" component={OrdenesPendientesAprobarFinanza} roles={[1,2,3,5]}/>
                <ProtectedRoute exact path="/ordenespendientesaprobacionfinanzas/:ordenid" component={OrdenesPendientesAprobarFinanzaVer} roles={[1,2,3,5]}/>

                <ProtectedRoute exact path="/historialordencreadasarea" component={HistorialOrdenCreadasArea} roles={[1,2,3,5]}/>
                <ProtectedRoute exact path="/historialordencreadasarea/:ordenid" component={HistorialOrdenCreadasAreaVer} roles={[1,2,3,5]}/>
                
                <ProtectedRoute exact path="/historialordencreadasfinanzas" component={HistorialOrdenCreadasFinanzas} roles={[1,2,3,5]}/>
                <ProtectedRoute exact path="/historialordencreadasfinanzas/:ordenid" component={HistorialOrdenCreadasFinanzasVer} roles={[1,2,3,5]}/>
                
                <ProtectedRoute exact path="/pendienteaprobacionpdf" component={PendienteAprobacionPdf} roles={[1,2,3,5]}/>
                <ProtectedRoute exact path="/pendienteaprobacionpdf/:ordenid" component={PendienteAprobacionPdfVer} roles={[1,2,3,5]}/>
                
                <ProtectedRoute exact path="/crearnotaentrega" component={ListCrearNotaEntrega} roles={[1,2,3,5]}/>
                <ProtectedRoute exact path="/crearnotaentrega/:ordenid" component={CrearNotaEntregaVer} roles={[1,2,3,5]}/>

                <ProtectedRoute exact path="/historialnotaentregasolicitante" component={HistorialNotaEntregaSolicitante} roles={[1,2,3,5]}/>
                <ProtectedRoute exact path="/historialnotaentregasolicitante/:ordenid/:numerodocumento" component={HistorialNotaEntregaSolicitanteVer} roles={[1,2,3,5]}/>

                <ProtectedRoute exact path="/historialnotaentregaproveedor" component={HistorialNotaEntregaProveedor} roles={[1,2,3,5]}/>
                <ProtectedRoute exact path="/historialnotaentregaproveedor/:ordenid/:numerodocumento" component={HistorialNotaEntregaProveedorVer} roles={[1,2,3,5]}/>

                <ProtectedRoute exact path="/historialcontrolleroc" component={HistorialOrdenController} roles={[1,2,3,5]}/>
                <ProtectedRoute exact path="/historialcontrollerocarea" component={HistorialOrdenControllerArea} roles={[1,2,3,5]}/>
                <ProtectedRoute exact path="/historialcontrollerocarea/:ordenid" component={HistorialOrdenControllerAreaVer} roles={[1,2,3,5]}/>

                <ProtectedRoute exact path="/historialcontrolleroc/:ordenid" component={HistorialOrdenControllerVer} roles={[1,2,3,5]}/>
              
                <ProtectedRoute exact path="/historialcontrollerne" component={HistorialNotaEntregaController} roles={[1,2,3,5]}/>
                <ProtectedRoute exact path="/historialcontrollernearea" component={HistorialNotaEntregaControllerArea} roles={[1,2,3,5]}/>
                <ProtectedRoute exact path="/historialcontrollernearea/:ordenid/:numerodocumento" component={HistorialNotaEntregaControllerAreaVer} roles={[1,2,3,5]}/>

                <ProtectedRoute exact path="/historialcontrollerne/:ordenid/:numerodocumento" component={HistorialNotaEntregaControllerVer} roles={[1,2,3,5]}/>

                <ProtectedRoute exact path="/facturapendientesolicitante" component={FacturasPendientesAdjuntarSolicitante} roles={[1,2,3,4,5]}/>
                <ProtectedRoute exact path="/facturapendientesolicitante/:ordenid" component={FacturasPendientesAdjuntarSolicitanteVer} roles={[1,2,3,4,5]}/>

                <ProtectedRoute exact path="/facturasrechazadas" component={RechazadasPdf} roles={[1,2,3,4,5]}/>
                <ProtectedRoute exact path="/facturasaprobadas" component={AprobacionPdf} roles={[1,2,3,4,5]}/>
                <ProtectedRoute exact path="/historalfacturasrechazadasdearea" component={RechazadasFacturacion} roles={[1,2,3,4,5]}/>
                <ProtectedRoute exact path="/historalfacturasaproadasdearea" component={AprobacionFacturacion} roles={[1,2,3,4,5]}/>

                <ProtectedRoute exact path="/documentorechazadopendienteadjuntar" component={RechazadasProveedor} roles={[1,2,3,5]}/>
                <ProtectedRoute exact path="/documentoaprobadas" component={AprobadasProveedor} roles={[1,2,3,5]}/>
              </Box>
            </Box>
        </Route>
      </Switch>
    </BrowserRouter>
  )
}

export default Router;