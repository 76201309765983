//  export const urlAzure = "https://appwebreq.mariaalmenara.net:2716/"  //azure 
  //     export const url = "https://appwebreq.mariaalmenara.net:2716/" //sap

    //export const urlAzure = "https://appwebreq.azurewebsites.net/"  //azure 
    export const urlAzure = "https://appwebreq.mariaalmenara.net:2716/"  //azure
    export const url = "https://appwebreq.mariaalmenara.net:2716/" //sap

      //export const urlAzure = "https://localhost:7004/"  //azure
   //export const url = "https://localhost:7004/" //sap

// // export const url = "https://localhost:7004/"